import { getPage } from 'lib/queries/getPage';
import { getPagePaths } from 'lib/queries/getPagePaths';
import Layout from 'components/Layout';
import Head from 'components/Head';
import Hero from 'components/Hero';
import ContentZone from 'components/ContentZone';
import References from 'blocks/References';
import SecondaryNavigation from 'components/SecondaryNavigation';
import FloatingActionButton from 'components/FloatingActionButton';
import Disclaimer from 'blocks/Disclaimer';
import Form from 'blocks/Form';
import { useRouter } from 'next/router';
import useAuth from 'lib/hooks/useAuth';
import Loader from 'components/Loader';

const Page = ({ data }) => {
    const router = useRouter();
    const { authLoading } = useAuth({ auth: data?.auth, router });
    if (router.isFallback || authLoading) {
        return <Loader />;
    }
    if (data.vanity_redirect) {
        return <Head {...data.head} />;
    }
    return (
        <Layout {...data}>
            <main id="main">
                {data?.hero && <Hero {...data.hero} />}
                {data?.floating_action_button && (
                    <FloatingActionButton {...data.floating_action_button} />
                )}
                {data?.secondary_navigation && (
                    <SecondaryNavigation {...data.secondary_navigation} />
                )}
                {data?.content_zone && <ContentZone {...data.content_zone} />}
                {data?.form && <Form {...data.form} />}
                {data?.references && <References {...data.references} />}
                {data?.disclaimer && <Disclaimer {...data.disclaimer} />}
            </main>
        </Layout>
    );
};

export default Page;

export const getStaticPaths = async ({ locales }) => {
    const paths = await getPagePaths(locales);
    return {
        paths,
        fallback: true,
    };
};

export const getStaticProps = async (context) => {
    const data = await getPage(context);
    if (!data) {
        return {
            notFound: true,
        };
    }
    return {
        props: {
            data,
        },
        revalidate: 60,
    };
};
