import Link from 'components/Link';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { useState } from 'react';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import breakpoints from 'assets/data/breakpoints';

const SecondaryNavigation = ({
    related_pages,
    active_page,
    title,
    sticky_primary_nav,
}) => {
    const [isActive, setIsActive] = useState(false);
    const { width } = useWindowDimensions();
    const handleToggle = () => {
        if (width < breakpoints.desktop) {
            setIsActive((prev) => !prev);
        }
    };
    return (
        <nav
            className={classNames('sn', {
                'sn--sticky-primary-nav': sticky_primary_nav,
            })}
        >
            <button
                className={classNames('sn__toggle', {
                    'sn__toggle--active': isActive,
                })}
                onClick={handleToggle}
                aria-expanded={isActive}
            >
                {title && <span className="sn__toggle-title">{title}</span>}
                <Icon
                    className="sn__toggle-icon"
                    icon="fa-light fa-chevron-down"
                />
            </button>
            <div className="sn__container">
                {title && <div className="sn__title">{title}</div>}
                <div
                    className={classNames('sn__links', {
                        'sn__links--active': isActive,
                    })}
                >
                    {related_pages &&
                        related_pages.map((page) => {
                            if (active_page === page.url_slug) {
                                return (
                                    <div
                                        key={page.id}
                                        className={'sn__link sn__link--active'}
                                    >
                                        {page.title}
                                    </div>
                                );
                            }
                            if (active_page !== page.url_slug) {
                                return (
                                    <Link
                                        key={page.id}
                                        href={page.url_slug}
                                        onClick={() => setIsActive(false)}
                                        className={'sn__link'}
                                    >
                                        {page.title}
                                    </Link>
                                );
                            }
                        })}
                </div>
            </div>
        </nav>
    );
};
export default SecondaryNavigation;
