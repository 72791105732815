import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

function useAuth({ auth, router }) {
    const path = router.asPath;
    const locale = router.locale;
    const pattern = new RegExp(auth?.protected_routes);
    const protected_route = pattern.test(path);
    let tenant = 'default'
    const { data: session, status } = useSession({
        required:
            protected_route &&
            process.env.Enable_SSO === 'true' &&
            !router.isFallback &&
            !path.startsWith('/login'),
        onUnauthenticated() {
            const route =
                router.locale === 'default'
                    ? `/login?callbackUrl=${path}`
                    : `${locale}/login?callbackUrl=/${locale}${path}`;
            router.push(route);
        },
    });

    useEffect(() => {
        // 1) Redirect the user to the home page if they are already logged in.
        // 2) Temporary redirect for old /healthcare-professionals login route.
        // 3) The tenant of AU shouldn't allow FR users and vice versa.
        if (session && session?.provider) {
            tenant = session.provider.split('-')[1] === 'ad' ? 'au' : session.provider.split('-')[1];
        }
        
        if (
            status === 'authenticated' && tenant === locale &&
            (path === '/login' || path === '/healthcare-professionals')
        ) {
            const redirect = router.locale === 'default' ? '/' : router.locale;
            router.push(redirect);
        }
        if (
            status === 'authenticated' && tenant !== locale && protected_route && auth
        ) {
            if (!path.includes('callbackUrl')) {
                const route = router.locale === 'default'
                    ? `/login?callbackUrl=${path}`
                    : `${locale}/login?callbackUrl=/${locale}${path}`;
                router.push(route);
            }
        }

    }, [status, locale, path, session]);


    //Called when the tenant is initialized to be 'default' and returns unexpected result
        if (session && session?.provider) {
            tenant = session.provider.split('-')[1] === 'ad' ? 'au' : session.provider.split('-')[1];
        }

    // Determine when loading screen will appear
    let authLoading = false;

    if (
        (status === 'loading' && protected_route) ||
        (status === 'loading' && path === '/login') ||
        (status === 'authenticated' && path === '/login' && tenant === locale) ||
        (status === 'authenticated' && path === '/healthcare-professionals' && tenant === locale) ||
        (status === 'authenticated' && tenant !== locale && protected_route && !path.includes('callbackUrl'))
    ) {
        authLoading = true;
    }

    return { authLoading };
}
export default useAuth;
